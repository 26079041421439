import React from "react";

import { SvgIcon } from "@material-ui/core";

export default function LinkedInIcon(){
    return (
        <SvgIcon viewBox="0 0 64 64" width="64" height="64">
        <g>
            <path fill="#0a0a0a" className="st0" d="M0,49.6V14.4c0-0.5,0.1-1,0.3-1.4c0.2-0.5,0.5-0.9,0.8-1.2c0.4-0.4,0.8-0.6,1.2-0.8c0.5-0.2,0.9-0.3,1.4-0.3
                h56.5c0.5,0,1,0.1,1.4,0.3c0.5,0.2,0.9,0.5,1.2,0.8c0.4,0.4,0.6,0.8,0.8,1.2c0.2,0.5,0.3,0.9,0.3,1.4v35.1c0,0.5-0.1,1-0.3,1.4
                c-0.2,0.5-0.5,0.9-0.8,1.2c-0.4,0.4-0.8,0.6-1.2,0.8c-0.5,0.2-0.9,0.3-1.4,0.3H3.8c-0.5,0-1-0.1-1.4-0.3c-0.5-0.2-0.9-0.5-1.2-0.8
                c-0.4-0.4-0.6-0.8-0.8-1.2C0.1,50.5,0,50.1,0,49.6z"/>
            <g>
                <g>
                    <path fill="#fff" className="st1" d="M39.8,27.2c0.7,2.5,1.7,6.5,2.4,9c1,3.9,1.3,4.7,2.2,5.6c0.6,0.6,1.3,1,1.8,1c1,0,2.2-1,2.7-2.1
                        c0.3-0.8,4.8-17.4,4.8-17.8c0-0.1-0.9-0.2-1.9-0.1l-2,0.1L48,29.6c-1.1,4.3-1.8,6.5-1.9,6.1C45.8,35,42.7,23,42.7,22.9
                        c0-0.1-0.9-0.1-2-0.1h-2L39.8,27.2z M27,23.6c-0.6,0.8-0.7,1.2-0.7,9.3v8.4l0.8,0.8c0.7,0.8,0.9,0.8,5,0.8h4.2v-3.4l-3.2-0.1
                        L30,39.2v-4.7l2-0.1l1.9-0.1v-3.4h-4.1v-4.7h6.6v-3.5H32C27.7,22.7,27.6,22.7,27,23.6z M19.8,26.8c0.7,0.6,0.8,0.7,0.8,5.8
                        c0,4.9,0,5.2-0.7,5.9c-0.5,0.5-1.1,0.7-2.2,0.7l-1.5,0l-0.1-6.5L16,26.2h1.5C18.6,26.2,19.3,26.4,19.8,26.8L19.8,26.8z
                        M12.5,32.8v10h3.5c3.9,0,5.6-0.5,6.7-2c1.2-1.5,1.4-2.8,1.3-8.8c-0.1-5.3-0.2-5.7-0.8-6.8c-1.2-1.9-2.5-2.4-6.9-2.4h-3.8V32.8z"
                        />
                </g>
            </g>
        </g>
        </SvgIcon>
    )
}